import { DarkModeSwitch } from 'react-toggle-dark-mode';
import React from 'react'

export const useDarkMode = () => {
  const date = new Date();
  const [darkMode, setDarkMode] = React.useState(date.getHours() > 18 || date.getHours() < 6)

	const DarkModeSwitcher = React.useMemo(() => {
		const DarkModeSwitcherComponent = (
			<DarkModeSwitch
				style={{ marginBottom: '2rem' }}
				checked={darkMode}
				onChange={() => {setDarkMode(!darkMode)}}
				sunColor='#181818'
				size={28}
				animationProperties={{
					dark: {
						circle: {
							r: 8,
						},
						mask: {
							cx: '50%',
							cy: '30%',
						},
						svg: {
							transform: 'rotate(40deg)',
						},
						lines: {
							opacity: 0,
						},
					},
					light: {
						circle: {
							r: 5,
						},
						mask: {
							cx: '100%',
							cy: '0%',
						},
						svg: {
							transform: 'rotate(40deg)',
						},
						lines: {
							opacity: 1,
						},
					},
					springConfig: { mass: 4, tension: 400, friction: 45 },
				}}
			/>	
		)
		return DarkModeSwitcherComponent
	}, [darkMode])

	return [
		darkMode,
		DarkModeSwitcher
	]
}
