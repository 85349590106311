/*
 * The purpose of this file is that this should be the only file that needs to be changed
 * when modifying any of the content on the webpage.
 */

// change icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used

export const socialList = [
	{
		name: 'GitHub',
		Icon: () => <FontAwesomeIcon icon={brands('github-alt')} size="m" />,
		link: 'https://github.com/alexshi0000'
	},
	{
		name: 'Linkedin',
		Icon: () => <FontAwesomeIcon icon={brands('linkedin-in')} size="m" />,
		link: 'https://linkedin.com/in/alexshi0000'
	},
	{
		name: 'Twitter',
		Icon: () => <FontAwesomeIcon icon={brands('twitter')} size="m" />,
		link: 'https://twitter.com/alexshi_zero'
	},
	{
		name: 'Instagram',
		Icon: () => <FontAwesomeIcon icon={brands('instagram')} size="m" />,
		link: 'https://www.instagram.com/alexshiee/'
	},
	{
		name: 'Email',
		Icon: () => <FontAwesomeIcon icon={solid('paper-plane')} size="sm" />,
		link: 'mailto: yuanshi948@gmail.com'
	},
]