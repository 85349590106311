import React from 'react'
import nameLogo from './name-logo-v2.png';
import nameLogoDark from './name-logo-dark-v2.png';
import './App.css';
import { socialList } from './AppData';
import { Helmet } from 'react-helmet'
import { useDarkMode } from './DarkMode';

function App() {
  const [darkMode, DarkModeSwitcher] = useDarkMode()
  const socialListDom = socialList.map(({name, Icon, link}) => {
    return (
      <div className="Social-logo">
        <a title={name} target='_blank' href={link} style={{color: 'inherit', textDecoration: 'none'}}>
          <Icon/ >
        </a>
      </div>
    )
  })
  return (
    <div className={!darkMode ? "App" : "App-dark"}>
      <Helmet style={[{
        "cssText": `
          html { background-color: ${darkMode ? "#181818" : "#ffffff"}; }
        `
      }]}>
        <link href='http://fonts.googleapis.com/css?family=Poppins' rel='stylesheet' type='text/css'/>
        <link href='http://fonts.googleapis.com/css?family=Roboto' rel='stylesheet' type='text/css'/>
        <meta name="description" content="Just my personal site where I do 👨🏻‍💻 🧑🏻‍🎨 🏗 and stuff"></meta>
        <meta property="og:title" content="Alex Shi" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="alexshi.me" />
        <meta property="og:image" content="https://bookface-images.s3.amazonaws.com/avatars/94dbab05441ba2ffb4dfb5289b64001da266072b.jpg" />
      </Helmet>
      <div className="Dark-toggle">
        {DarkModeSwitcher}
      </div>
      <header className={!darkMode ? "App-header" : "App-header-dark"}>
        <img src={!darkMode ? nameLogo : nameLogoDark} className="Name-logo" alt="logo" />
        <div className="Social-flexbox">
          {socialListDom}
        </div>
        <p className={!darkMode ? 'Calendly-line': 'Calendly-line-dark'}>
          or book a time in my <a className={!darkMode ? "Calendly-link": 'Calendly-link-dark'} href="https://calendly.com/alexshi-zero" target="_blank">calendly</a>
        </p>
      </header>
    </div>
  );
}

export default App;
